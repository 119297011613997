<!--
 * @Descripttion: 学院
 * @Author: 张明尧
 * @Date: 2021-02-24 13:40:42
 * @LastEditTime: 2021-03-23 11:24:53
-->
<template>
  <div class="school-div">
    <div class="school-menu">
      <div v-if="school_menus.length > 0" class="school-menu-div">
        <div @click="active_index(_menu)" class="school-menu-div-list" v-for="(menu_list, _menu) in school_menus" :key="_menu">
          <i :class="actived == _menu && 'jeicon-work2-on' || 'jeicon-work2'" class="school-menu-div-list-icon jeicon"></i>
          {{menu_list.text}}
          <div class="school-menu-div-list-active" v-if="actived == _menu">
            
          </div>
        </div>
      </div>
    </div>
    <div v-loading="school_main.length <= 0 || !showMenus" class="school-content">
      <div class="main">
        <div v-if="school_menus[actived] && school_menus[actived].children.length > 0 && showMenus" class="main-left">
          <el-menu
            v-if="showMenus"
            @select="menuSelect"
            :default-active="activedMenu"
            class="el-menu-vertical-demo">
            <el-submenu v-for="(menu_child2, _menu_child2) in shcool_menu2" :key="`_menu_child2${_menu_child2}`" :index="JSON.stringify(menu_child2)">
              <template slot="title">
                <span>{{menu_child2.text}}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-for="(menu_child3, _menu_child3) in menu_child2.children" :key="`_menu_child3${_menu_child3}`" :index="JSON.stringify(menu_child3)">
                    <span :class="menu_child3.text.length > 8 && 'textOmit'">{{menu_child3.text}}</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-for="(menu_child2, _menu_child2) in shcool_menu2_noChildren" :key="`${_menu_child2}__menu_child2no`" :index="JSON.stringify(menu_child2)">
              <span slot="title">{{ menu_child2.text }}</span>
            </el-menu-item>
          </el-menu> 
        </div>
        <div v-if="school_main.length > 0" class="main-right">
          <div class="main-right-title">
            {{school_main[0].name}}
          </div>
          <div v-for="(datas, _datas) in school_main[0].datas" :key="`${_datas}_main`" class="main-right-content">
            <div v-if="datas.XQY_XSBT_CODE == 1 && datas.XQY_BT" class="main-right-text-title">
              {{datas.XQY_BT}}
            </div>
            <div v-if="datas.XQY_SPXXSMLX_CODE == 'WZ' && datas.XQY_WZ" class="main-right-text">
              {{ datas.XQY_WZ }}
            </div>
            <div v-if="datas.XQY_SPXXSMLX_CODE == 'TP' && datas.XQY_TP" class="main-right-img">
              <img :src="datas.XQY_TP" alt="">
            </div>
              <div
                v-if="datas.XQY_SPXXSMLX_CODE == 'SP' && datas.XQY_SP"
                :id="`alyun`"
                ref="player"
                class="prism-player video-src"
              />
            <div v-if="datas.XQY_SPXXSMLX_CODE == 'TW' && datas.XQY_TW" class="main-right-html">
              <div v-html="datas.XQY_TW"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSchoolMenu, getVideoDetails, getloadTree, getloadProductInfo, getWebsiteVisitLog } from '@/util/actions/api'
// import Datas from './js/common'
export default {
  data() {
    return {
      school_menus: [],
      school_main: [],
      actived: 0,
      showMenus: true, // 默认
      activedMenu: '', // 当前点击的ID
    }
  },
  computed: {
    shcool_menu2: function() {
      return this.school_menus[this.actived].children.filter((menu) => menu.children.length > 0);
    },
    shcool_menu2_noChildren: function() {
      return this.school_menus[this.actived].children.filter((menu) => menu.children.length <= 0);
    }
  },
  created() {
    this.getSchoolMenuVue();
  },
  methods: {
    // 点击一级菜单
    active_index(index) {
      this.actived = index;
      this.menuOneData(this.school_menus[this.actived]); // 重新获取当前菜单默认的内容
      const activeObj = JSON.parse(this.activedMenu);
      // 站点访问记录
      getWebsiteVisitLog({
        pageName: `企业大脑-学院-${this.school_menus[this.actived].text}`, // 企业大脑站点访问记录
        pageCode: 'danao_school',
        url: 'http://www.jedanao.com/school',
      })
      this.getMenuData(activeObj.id, activeObj.nodeType).then(res => {
        this.school_main = [];
        res.obj.forEach(forRes => {
          this.school_main.push({name: forRes.name, datas: this.filterSchoolMain(forRes.detailPage)});
        })
      })
    },
    // 获取当前头部菜单切换默认的数据
    menuOneData(datas) {
      if(datas.children && datas.children.length > 0) {
        this.menuOneData(datas.children[0]);
      } else {
        this.activedMenu = JSON.stringify(datas);
        this.showMenus = false;
        this.$nextTick(() => {
          this.showMenus = true;
        })
      }
    },
    // 菜单点击
    menuSelect(val) {
      this.activedMenu = val;
      const activeObj = JSON.parse(this.activedMenu);
      document.body.scrollIntoView({behavior:'smooth', block: 'start'});
      this.getMenuData(activeObj.id, activeObj.nodeType).then(res => {
        this.school_main = [];
        res.obj.forEach(forRes => {
          this.school_main.push({name: forRes.name, datas: this.filterSchoolMain(forRes.detailPage)});
        })
      })
    },
    // 获取菜单
    getSchoolMenuVue() {
      getloadTree().then(res => {
        const resData = this.forMenuData(res.obj.tree.children);
        this.school_menus = resData;
        this.activedMenu = JSON.stringify(this.school_menus[this.actived].children[0].children[0]);
        this.active_index(this.actived);
      })
    },
    // 点击指定ID菜单，获取指定数据
    getMenuData(id, nodeType) {
      return getloadProductInfo({id, nodeType}).then(res => res);
    },
    // 遍历数据转为所需要的数据
    forMenuData(arr) {
      return arr;
    },
    // 获取内容
    getSchoolMenuMainVue(id) {
      return getSchoolMenu(id).then(res => res)
    },
    // 整理显示内容
    filterSchoolMain(arr) {
      arr = arr.map(res => {
        // 如果是图片格式
        if(res.XQY_SPXXSMLX_CODE == 'TP') {
          const imgKey = JSON.parse(res.XQY_TP);
          res.XQY_TP = `https://suanbanyun.com/je/document/preview?fileKey=${imgKey[0].path}`;
          // res.XQY_TP = `http://dev.suanbanyun.com/je/document/preview?fileKey=${imgKey[0].path}`;
        }
        // 如果是视频模式
        if(res.XQY_SPXXSMLX_CODE == 'SP') {
          getVideoDetails({videoId: 'ae730dd8fbf441019fdaa1e4d07c92d4'}).then(video => {
            if(video.success) {
              res = {...res, ...video};
              this.doPlayer(video.obj.VideoId, video.obj.PlayAuth, video.obj);
            }
          })
        }
         // 如果是图文模式
        if(res.XQY_SPXXSMLX_CODE == 'TW') {
          res.XQY_TW && (res.XQY_TW = res.XQY_TW.replace(/&lt;/g, '<'));
          res.XQY_TW && (res.XQY_TW = res.XQY_TW.replace(/&gt;/g, '>'));
          res.XQY_TW && (res.XQY_TW = res.XQY_TW.replace(/&quot;/g, '"'))
          if (res.XQY_TW && res.XQY_TW.includes('img')) {
            res.XQY_TW = res.XQY_TW.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (ma, ca) => {
              let imgStr = '';
              ca.includes('http') && (imgStr = ca) || (imgStr = `https://suanbanyun.com${ca}`);
              // ca.includes('http') && (imgStr = ca) || (imgStr = `http://dev.suanbanyun.com${ca}`);
              return `<img src="${imgStr}" title="image.png" alt="image.png">`;
            });
          }
        }
        return res;
      })
      const compare = (key) => {
        return (obj1, obj2) => {
          let value1 = obj1[key]
          let value2 = obj2[key]
          if (value1 > value2) {
            return 1;
          } else if (value1 < value2) {
            return -1;
          } else {
            return 0
          }
        }
      }
      return arr.sort(compare(`SY_ORDERINDEX`));
    },
    doPlayer(vid, playauth, content) {
      // if (content.player) {
      //   if (content.player.replayByVidAndPlayAuth) {
      //     content.player.replayByVidAndPlayAuth(vid, playauth);
      //   } else {
      //     content.player.dispose();
      //     delete content.player;
      //     content.$refs.playauth.el.innerHTML = '';
      //     content.doPlayer(vid, playauth);
      //   }
      // } else {
        content.player = new window.Aliplayer({
          id: 'alyun',
          qualitySort: 'asc',
          format: 'm3u8',
          mediaType: 'video',
          encryptType: 1,
          vid,
          playauth,
          // width: '100%',
          height: '500px',
          autoplay: false,
          isLive: false,
          cover: content.CoverUrl,
          rePlay: true,
          playsinline: true,
          preload: true,
          controlBarVisibility: 'hover',
          useH5Prism: true,
        }, (() => {
          console.log('The player is created');
        }));
      // }
    }
  }
}
</script>

<style lang="scss" socped>
  .school-div {
    min-height: 100vh;
    color: #3f3f3f;
    .textOmit {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    .school-content {
      width: 1400px;
      margin: 60px auto;
      .main {
        display: flex;
        justify-content: space-between;
        .main-left {
          width: 230px;
        }
        .main-right {
          width: 1200px;
          margin: 0 auto;
          .main-right-content {
            padding-left: 100px;
            margin: 30px 0;
          }
          .main-right-title {
            font-size: 35px;
            font-weight: 600;
            text-align: center;
          }
          .main-right-text-title {
            font-size: 22px;
            font-weight: 500;
            margin: 30px 0;
          }
          .main-right-text {
            line-height: 2;
            font-size: 16px;
          }
          .main-right-img {
            img {
              width: 100%;
              height: auto;
            }
          }
          .main-right-video {
            .video-src {
              width: 100%;
              height: auto;
            }
          }
          .main-right-html {
            // margin: 30px auto;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    .school-menu {
      height: 60px;
      box-shadow: 1px 0px 7px 0px rgba(180, 179, 179, 0.34);
      .school-menu-div {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        .school-menu-div-list {
          width: 12%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          position: relative;
          cursor: pointer;
          .school-menu-div-list-icon {
            color: #0358FD;
            margin-right: 5px;
            font-size: 12px;
          }
        }
        .school-menu-div-list-active {
          position: absolute;
          bottom: 0;
          width: 100px;
          height: 2px;
          background: #0358FD;
          // border-bottom: 2px solid #0358FD;
        } 
      }
    }
  }
</style>